import React from "react";
import TicketGenerator from "./TicketGenerator";
import "./App.css";

const App = () => {
  return (
    <div className="app">
      <header className="hero">
        <img src="/assets/logo.png" alt="8bitWarriors Logo" className="logo" />
        <h1>ORIENTATION</h1>
        <h2>2024</h2>
        <p className="subtitle">BUILDING A TECH-DRIVEN COMMUNITY</p>
        <p className="event-details">
          <strong>Date:</strong> 23 November 2024 <br />
          <strong>Location:</strong> LNTCE Auditorium
        </p>
      </header>

      <section id="ticket-section" className="ticket-section">
        <h2>Your Ticket</h2>
        <TicketGenerator />
        
      </section>
    </div>
  );
};

export default App;
