import React, { useState } from "react";
import html2canvas from "html2canvas";
import "./TicketGenerator.css";

const TicketGenerator = () => {
  const [ticketGenerated, setTicketGenerated] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");
  const [row, setRow] = useState("");
  const [seat, setSeat] = useState("");

 const generateTicketDetails = () => {
  // Helper function to format numbers with leading zeros
  const formatNumber = (num) => String(num).padStart(2, "0");

  const randomTicketNumber = Math.floor(100000 + Math.random() * 900000);
  const randomRow = formatNumber(Math.floor(3 + Math.random() * 13)); // Rows 03–15
  const randomSeat = formatNumber(Math.floor(1 + Math.random() * 30)); // Seats 01–30

  setTicketNumber(randomTicketNumber);
  setRow(randomRow);
  setSeat(randomSeat);
  setTicketGenerated(true);
};


  const downloadTicket = () => {
    const ticketElement = document.getElementById("ticket");
    html2canvas(ticketElement).then((canvas) => {
      const link = document.createElement("a");
      link.download = "ticket.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <div className="ticket-generator">
      {!ticketGenerated && (
        <button className="generate-button" onClick={generateTicketDetails}>
          Generate Ticket
        </button>
      )}
      {ticketGenerated && (
        <div className="ticket-display">
          <div id="ticket" className="ticket-container">
            <img
              src="/assets/ticket.svg"
              alt="Ticket Design"
              className="ticket-background"
            />
            <div className="ticket-overlay">
              <p className="ticket-number">{ticketNumber}</p>
              <p className="row-number">{row}</p>
              <p className="seat-number">{seat}</p>
            </div>
          </div>
          <button className="download-button" onClick={downloadTicket}>
            📥 Download Your Ticket
          </button>
        </div>
      )}
    </div>
  );
};

export default TicketGenerator;
